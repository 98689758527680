<template>
    <v-autocomplete
        ref="app_autocomplete"
        v-model="internal_value"
        :autofocus="autofocus"
        :clearable="clearable"
        :disabled="disabled"
        :error-messages="translate(error_message)"
        :filled="readonly"
        :hide-details="hide_details"
        :item-text="computed_item_text"
        :item-value="item_value"
        :items="items"
        :label="translate(label)"
        :loading="is_loading"
        :placeholder="translate(placeholder)"
        :prepend-inner-icon="prependInnerIcon"
        :readonly="readonly"
        :return-object="return_object"
        :rules="rules"
        :search-input.sync="search_text"
        @blur="$emit('blur')"
        @change="$emit('change')"
        @focus="internal_width = $refs.app_autocomplete.$el.clientWidth"
        aria-autocomplete="off"
        autocomplete="off"
        type="text"
        dense
        no-filter
        outlined
    >
        <template #selection="{ item }">
            <!-- Width 78% allows the input to stay on the same row so we dont place the input on the second row -->
            <div
                class="app-text--overflow-hidden-ellipsis"
                style="max-width: 78%"
            >
                {{ deep_get(item, computed_item_text) || '-' }}
            </div>
        </template>

        <template #append>
            <v-progress-circular
                v-if="loading_next"
                class="app-object--absolute-center"
                color="primary"
                indeterminate
            />
            <v-tooltip
                v-else-if="show_resource_information_icon"
                class="ma-0 pa-0"
                bottom
            >
                <template #activator="{ on, attrs }">
                    <v-icon
                        v-bind="attrs"
                        v-on.stop="on"
                        @click.stop=""
                    >
                        mdi-information-outline
                    </v-icon>
                </template>

                <div class="ma-2">
                    <div
                        v-for="(key, index) in resource_body_attributes.filter((k) => !!deep_get(selected_item, k))"
                        :key="index"
                    >
                        {{ locale_key(key, ['item'], true) }}:
                        {{ translate_unknown_value(deep_get(selected_item, key), ['item']) }}
                    </div>
                </div>
            </v-tooltip>
        </template>

        <template #prepend-item>
            <slot name="prepend_item"></slot>
        </template>

        <template
            v-if="search_card"
            #item="{ item, on }"
        >
            <v-list-item
                :disabled="is_disabled(item)"
                :style="`max-width: ${internal_width}px`"
                v-on="on"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        {{ format_dict_value(deep_get(item, search_card.title.value), search_card.title) }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                        v-for="sub_title in search_card.body"
                        :key="sub_title.label"
                    >
                        <span v-if="!sub_title.hide_key">
                            {{ translate(sub_title.label) }}:
                            {{ format_dict_value(deep_get(item, sub_title.value), sub_title) }}
                        </span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="is_disabled(item) && disabled_text">
                        {{ translate(disabled_text) || $t('common.disabled') }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
        <template
            v-else
            #item="{ item, on }"
        >
            <slot
                :item="item"
                :is_selected="selected_item === item || selected_item === item?.value"
                :is_disabled="is_disabled(item)"
                :on="on"
                name="item"
            >
                <v-list-item
                    :class="{ 'app-object--highlighted': selected_item === item || selected_item === item?.value }"
                    :disabled="is_disabled(item)"
                    :style="`max-width: ${internal_width}px`"
                    v-on="on"
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ deep_get(item, computed_item_text) || '-' }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                            v-for="key in resource_body_attributes.filter((x) => !!deep_get(item, x))"
                            :key="key"
                            class="mt-1"
                        >
                            {{ locale_key(key, ['item'], true) }}:
                            {{ translate_unknown_value(deep_get(item, key), ['item']) }}
                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-list-item-action-text v-if="is_disabled(item) && disabled_text">
                            {{ translate(disabled_text) || $t('common.disabled') }}
                        </v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item>
            </slot>
        </template>

        <template #append-item>
            <div v-intersect="get_next_items" />
        </template>
    </v-autocomplete>
</template>

<script>
import throttle from 'lodash/throttle'
import { item_mixin } from '@/mixins/item'

export default {
    name: 'AppAutocomplete',
    components: {},
    mixins: [item_mixin],
    props: {
        value: [Object, String],
        resource: String,
        item_value: String,
        item_text: String,
        searchable_columns: Array,
        label: String,
        disabled: Boolean,
        clearable: Boolean,
        readonly: Boolean,
        prependInnerIcon: String,
        placeholder: String,
        return_object: { type: Boolean, default: false },
        init_filter: {
            type: Object,
            default: () => {},
        },
        init_sort: {
            type: String,
        },
        custom_items: Array,
        search_card: Object /* {default: {title: {value: "_id", label: "_id"}}}*/,
        disabled_items_filter_callback: Function,
        disabled_text: String,
        custom_where_callback: Function,
        hide_details: Boolean,
        property: Object,
        error_message: {
            type: String,
            default: '',
        },
        rules: {
            type: Array,
            default: () => [],
        },
        autofocus: Boolean,
        // example header: {title: {value: "ocr", label: $t(common.ocr_number)} {sub_title:[{value: "", label:""}}
    },
    data: function () {
        return {
            items: [],
            search_text: null,
            page: 1,
            max_results: 15,
            last_request: false,
            loading_next: false,
            is_loading: false,
            internal_width: 0,
            throttle_get_entries: throttle(function () {
                this.get_entries()
            }, 1000),
        }
    },
    computed: {
        computed_item_text() {
            if (this.resource_header_attribute) return this.resource_header_attribute
            if (this.item_text) return this.item_text
            if (!this.resource_schema) return '_id'

            /*Backup solution to find a valid item text*/
            let priority = ['partner_name', 'name', 'description', 'reference_number']
            let required_non_reference = this.resource_schema?.required?.filter((x) => !this.resource_schema['properties']?.[x]?.['$ref']) || []
            let valid_item_texts = this.resource_text_search.filter((x) => required_non_reference.indexOf(x) > -1)

            let prioritized_item_texts = valid_item_texts.filter((x) => priority.indexOf(x) > -1)
            if (prioritized_item_texts.length) return prioritized_item_texts[0]
            return valid_item_texts.length ? valid_item_texts[0] : required_non_reference.length ? required_non_reference[0] : '_id'
        },
        disabled_items() {
            if (!this.disabled_items_filter_callback) return []

            return this.disabled_items_filter_callback(this.items)
        },
        selected_item() {
            if (!this.internal_value) return null

            if (this.is_object(this.internal_value)) {
                return this.internal_value
            }

            if (this.items.length === 1) {
                return this.items[0]
            }

            return this.items.filter((x) => this.deep_get(x, this.item_value) === this.internal_value)[0]
        },
        resource_header_attribute() {
            return this.resource_search_result_format?.header_attribute
        },
        resource_body_attributes() {
            return this.resource_search_result_format?.body_attributes || []
        },
        show_resource_information_icon() {
            return this.internal_value && this.resource_body_attributes.filter((x) => !!this.deep_get(this.selected_item, x)).length
        },
        internal_value: {
            get() {
                return this.value
            },
            set(val) {
                if (val === null) {
                    this.throttle_get_entries()
                }
                this.$emit('input', val)
            },
        },
    },
    watch: {
        search_text(new_val) {
            if (this.custom_items) {
                this.$emit('search_text', new_val)
            } else {
                this.throttle_get_entries()
            }
        },
        custom_items(val) {
            this.items = val || []
        },
        init_filter(new_val, old_val) {
            if (!new_val || !old_val) return
            if (!this.object_equals(new_val, old_val)) this.throttle_get_entries()
        },
    },
    methods: {
        async get_entries() {
            if (this.custom_items) return
            this.last_request = false
            this.page = 1

            const params = {
                where: this.where_filter(),
                page: this.page,
                max_results: this.max_results,
            }
            if (this.init_sort) {
                params.sort = this.init_sort
            }

            let result = await this.api_get({
                url: '/' + this.resource,
                params: params,
            })
            this.is_loading = false
            if (result && result.status === 200) {
                this.items = result.data._items
            }
        },
        async get_next_items() {
            if (this.custom_items) {
                return this.$emit('get_next_items')
            }
            if (this.last_request) return
            this.loading_next = true

            const params = {
                where: this.where_filter(),
                page: this.page,
                max_results: this.max_results,
            }
            if (this.init_sort) {
                params.sort = this.init_sort
            }

            let request = await this.api_get({
                url: '/' + this.resource,
                params: params,
            })

            if (request.status === 200) {
                let items = request.data['_items']
                this.items = [...this.items, ...items]
                this.page += 1
                if (items.length < this.max_results) {
                    this.last_request = true
                }
            }
            this.loading_next = false
        },
        is_disabled(item) {
            return this.disabled_items.indexOf(item) > -1
        },
        where_filter() {
            if (this.custom_where_callback) {
                return this.custom_where_callback(this.search_text, this.init_filter)
            }

            if (this.is_object_id(this.internal_value)) {
                return {
                    _id: this.internal_value,
                }
            }

            return this.where(this.resource, this.search_text, this.init_filter)
        },
        is_object_id(value) {
            if (!value || typeof value !== 'string') return false

            return value.length === 24 && !isNaN(Number(`0x${value}`))
        },
        focus() {
            this.$refs.app_autocomplete.focus()
            this.$refs.app_autocomplete.activateMenu()
        },
        blur() {
            this.$refs.app_autocomplete.blur()
        },
    },
    created() {
        if (this.custom_items) {
            this.items = this.custom_items
        } else {
            this.get_entries()
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.autofocus) {
                this.$refs.app_autocomplete.activateMenu()
            }
        })
    },
}
</script>

<style scoped></style>
